<template>
    <div class="result_page">
        <div class="result_title" style="padding-top: 20px">
            <SmallTitle tit="计算结果筛选" />
        </div>
        <div class="search_box">
            <el-row>
                <div class="li">
                    <h6>EGPM标记</h6>
                    <div class="int_box">
                        <el-select multiple collapse-tags placeholder="全部" v-model="form.egpmFlag">
                            <el-option label="E" value="E" />
                            <el-option label="G" value="G" />
                            <el-option label="P" value="P" />
                            <el-option label="M" value="M" />
                        </el-select>
                    </div>
                </div>
                <div class="li">
                    <h6>过往12期上榜数</h6>
                    <div class="int_box">
                        <el-input type="number" v-model.number="form.periodMin" :placeholder="defaultData?.periodMin?.toString() || ''"></el-input>-
                        <el-input type="number" v-model.number="form.periodMax" :placeholder="defaultData?.periodMax?.toString() || ''"></el-input>
                    </div>
                </div>
            </el-row>
            <el-row>
                <div class="li">
                    <h6>门店增长率</h6>
                    <div class="int_box">
                        <el-input type="number" v-model.number="form.shopIncMin" :placeholder="defaultData?.shopIncMin?.toString() || ''"></el-input>-
                        <el-input type="number" v-model.number="form.shopIncMax" :placeholder="defaultData?.shopIncMax?.toString() || ''"></el-input>
                    </div>
                </div>
                <div class="li">
                    <h6>城市增长率</h6>
                    <div class="int_box">
                        <el-input type="number" v-model.number="form.cityIncMin" :placeholder="defaultData?.cityIncMin?.toString() || ''"></el-input>-
                        <el-input type="number" v-model.number="form.cityIncMax" :placeholder="defaultData?.cityIncMax?.toString() || ''"></el-input>
                    </div>
                </div>
                <div class="li">
                    <h6>1-3线新店占比</h6>
                    <div class="int_box">
                        <el-input type="number" v-model.number="form.tierRatioMin" :placeholder="defaultData?.tierRatioMin?.toString() || ''"></el-input>-
                        <el-input type="number" v-model.number="form.tierRatioMax" :placeholder="defaultData?.tierRatioMax?.toString() || ''"></el-input>
                    </div>
                </div>
            </el-row>
            <el-row>
                <div class="li">
                    <h6>店龄排序得分</h6>
                    <div class="int_box">
                        <el-input type="number" v-model.number="form.shopAgeScoreMin" :placeholder="defaultData?.shopAgeScoreMin?.toString() || ''"></el-input>-
                        <el-input type="number" v-model.number="form.shopAgeScoreMax" :placeholder="defaultData?.shopAgeScoreMax?.toString() || ''"></el-input>
                    </div>
                </div>
                <div class="li">
                    <h6>关店率排序得分</h6>
                    <div class="int_box">
                        <el-input type="number" v-model.number="form.closeRatioScoreMin" :placeholder="defaultData?.closeRatioScoreMin?.toString() || ''"></el-input>-
                        <el-input type="number" v-model.number="form.closeRatioScoreMax" :placeholder="defaultData?.closeRatioScoreMax?.toString() || ''"></el-input>
                    </div>
                </div>
                <div class="li">
                    <h6>品牌平均客单价</h6>
                    <div class="int_box">
                        <el-input type="number" v-model.number="form.avgPriceMin" :placeholder="defaultData?.avgPriceMin?.toString() || ''"></el-input>-
                        <el-input type="number" v-model.number="form.avgPriceMax" :placeholder="defaultData?.avgPriceMax?.toString() || ''"></el-input>
                    </div>
                </div>
            </el-row>
        </div>
        <div class="btn_box">
            <el-button type="info" @click="reset">重置</el-button>
            <el-button type="primary" @click="search">搜索</el-button>
        </div>
        <div class="result_title search_title">
            <SmallTitle tit="计算结果" />
            <div class="search_input">
                <el-input placeholder="品牌名称" clearable style="width: 300px" v-model="form.keyword" @keydown.enter="search">
                    <template #append>
                        <el-button :icon="Search" @click.stop="search" />
                    </template>
                </el-input>
            </div>
        </div>
        <div class="result_container">
            <div class="tab">
                <li v-for="item in tabs" :key="item.path" :class="{ active: $route.path == item.path }" @click="switchTab(item)">{{ item.name }}</li>
            </div>
            <div class="con">
                <router-view />
            </div>
        </div>
    </div>
</template>
<script setup>
import SmallTitle from "@/components/common/SmallTitle.vue";
import { useRoute, useRouter } from "vue-router";
import useResultStore from "@/store/egpm-brand/useResultStore";
import { reactive, ref, onBeforeMount } from "vue";
import { cloneDeep } from "loadsh";
import { apiEgpmTaskResultFilters } from "@/request/egpmBrandApi";
import { Search } from "@element-plus/icons-vue";
// import useDict from "./useDict";

const resultStore = useResultStore();
const route = useRoute();
const router = useRouter();
const tabs = [
    { name: "EGPM单期图表", path: "/egpm/brand/details/result/single" },
    { name: "地域分布", path: "/egpm/brand/details/result/list" },
];
const form = reactive({
    keyword: "",
    egpmFlag: [], // EGPM标识:E,G,P,M
    periodMin: null, // 期数
    periodMax: null,
    shopIncMin: null, // 门店增长率
    shopIncMax: null,
    cityIncMin: null, // 城市增长率
    cityIncMax: null,
    tierRatioMin: null, // 1-3线新店占比
    tierRatioMax: null,
    shopAgeScoreMin: null, // 店龄排序得分
    shopAgeScoreMax: null,
    closeRatioScoreMin: null, // 关店率排序得分
    closeRatioScoreMax: null,
    avgPriceMin: null, // 品牌平均客单价
    avgPriceMax: null,
});
const defaultData = ref({}); // 默认值

// 字典
// const { dicts } = useDict({ form });

// 搜索
function search() {
    resultStore.handleSearch(cloneDeep(form));
}

// 获取初始化值
async function getDefault() {
    const { data } = await apiEgpmTaskResultFilters({ taskId: route.query.id });
    defaultData.value = data || {};
}

// 重置
function reset() {
    form.keyword = "";
    form.egpmFlag = [];
    form.periodMin = "";
    form.periodMax = "";
    form.shopIncMin = "";
    form.shopIncMax = "";
    form.cityIncMin = "";
    form.cityIncMax = "";
    form.tierRatioMin = "";
    form.tierRatioMax = "";
    form.shopAgeScoreMin = "";
    form.shopAgeScoreMax = "";
    form.closeRatioScoreMin = "";
    form.closeRatioScoreMax = "";
    form.avgPriceMin = "";
    form.avgPriceMax = "";
    search();
}

// 切换
function switchTab(item) {
    router.replace({
        path: item.path,
        query: {
            reportId: route.query.reportId,
            id: route.query.id,
        },
    });
}

onBeforeMount(() => {
    resultStore.setSreen({});
    getDefault();
});
</script>

<style lang="scss" scoped>
.result_page {
    .search_box {
        padding: 0 20px;
        padding-top: 20px;
        .li {
            margin-right: 20px;
            margin-bottom: 10px;

            h6 {
                font-weight: normal;
                color: #818a9b;
                padding-bottom: 6px;
                font-size: 14px;
            }

            .el-select {
                width: 300px;
            }

            :deep(.el-input__inner) {
                border-radius: 7px;
            }

            .int_box {
                display: flex;
                align-items: center;

                .el-input {
                    width: 147px;
                }
            }
        }
    }
    .search_form {
        padding: 20px;
        padding-bottom: 0;
        .row {
            display: flex;
            margin-bottom: 14px;
            flex-wrap: wrap;
            .item {
                margin-right: 12px;
                margin-bottom: 12px;
                h6 {
                    font-weight: normal;
                    color: #818a9b;
                    padding-bottom: 6px;
                    font-size: 14px;
                }
                .el-input,
                .el-select {
                    width: 200px;
                }
            }
        }
    }
    .btn_box {
        display: flex;
        justify-content: center;
        padding-top: 10px;
    }
    .search_title {
        position: relative;
        .search_input {
            position: absolute;
            left: 165px;
            bottom: -62px;
            z-index: 2;
            display: flex;
            align-items: center;
        }
    }
    .result_container {
        border-radius: 4px;
        margin-top: 15px;
        position: relative;
        padding-left: 150px;
        .tab {
            width: 150px;
            position: absolute;
            top: 0;
            left: 0;
            li {
                text-align: center;
                line-height: 50px;
                cursor: pointer;
                border-radius: 4px;
                padding: 0 10px;
                background-color: #f2f2f2;
                user-select: none;
                &.active {
                    background-color: var(--primary-color);
                    color: #fff;
                }
            }
        }
        .con {
            border: 1px solid #f2f2f2;
            border-radius: 4px;
            padding: 15px;
            min-height: 500px;
        }
    }
}
</style>
